import React, { Component } from "react";

class KonmariTOC extends Component {
  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html:
            `<ul class="toc">
              <li><a href="/konmari-overview/">Overview</a></li>
              <li><a href="/konmari-clothes/">Clothes</a></li>
              <li>Books</li>
              <li>Papers</li>
              <li>Komono</li>
              <li>Sentimental</li>
            </ul>
            `}} />
      </div>
    );
  }
}

export default KonmariTOC;
